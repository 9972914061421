import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'

function Mining() {
    const [investment, setinvestment] = useState(0)
    const [coinToUse, setcoinToUse] = useState("")
    const [amountToInvest, setamountToInvest] = useState("0")
    const [amount, setamount] = useState("")
    const navigate = useNavigate()


    
    // LOGIN CHEK
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    function cleanDate(d) {
        var date = new Date(d);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        console.log("Date: " + date.getDate() +
            "/" + (months[date.getMonth() + 1]) +
            "/" + date.getFullYear() +
            " " + date.getHours() +
            ":" + date.getMinutes() +
            ":" + date.getSeconds());
        return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
    }


    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setOpen(!open);
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);

                } else {
                    setloggedin(false);
                    setOpen(!open);
                    navigate("/login");
                }
            });
        }
    }, []);


    const fetchuserdata = (userid) => {
        var docRef = db.collection("users").doc(userid);
        docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloggedin(true)
                    setOpen(!open);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/login");
    };
        // LOGIN CHEK

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(investment)
    }, [investment])


    const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);

    const setinvestments = (data) => {
        dispatch({ type: "setselectedinvestment", snippet: data });
    };


    const setcointoinvestwith = (data) => {
        dispatch({ type: "setcointoinvestwith", snippet: data });
    };
    function addHoursToDate(date, hours) {
        return new Date(new Date(date).setHours(date.getHours() + hours));
    }


    const investments = [
        {
            id: "1",
            name: "Amatuer Plan",
            minimum: "200",
            Maximum: "5000",
            profits: "12",
            duration: "7"
        },
        {
            id: "2",
            name: "Master Plan",
            minimum: "5000",
            Maximum: "50000",
            profits: "15",
            duration: "7"
        },
        {
            id: "3",
            name: "Ultimate Plan",
            minimum: "25000",
            Maximum: "100000",
            profits: "20",
            duration: "7"
        },
        {
            id: "4",
            name: "Compound Plan",
            minimum: "1000",
            Maximum: "100000000",
            profits: "25",
            duration: "7"
        },
    ]
    const CreatUserObj = async (obj, subsription) => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        let uobj = {
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            fullname: userdetails.fullname,
            email: userdetails.email,
            userid: userids,
            info: obj,
            subsription: subsription

        };
        const userdetail1 = await db
            .collection("investments")
            .add(uobj)
            .then(function () {
                console.log("Document successfully written!");
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    };
    const planactivation = (value) => {
        let SelectedInvestment = investments.find(o => o.id === value);
        console.log("nnuhuhuy8b")
        if (amount !== "") {
            if (userdetails.currentMining == null) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: `Do you  want to invest in ${SelectedInvestment.name}`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, continue'
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (
                            parseInt(userdetails.balance) !== 0 &&
                            parseInt(amount) <= parseInt(userdetails.balance)
                        ) {

                            if (parseInt(amount) >= SelectedInvestment.minimum && parseInt(amount) <= SelectedInvestment.Maximum) {
                                let amount1 = parseInt(amount);
                                // if (value == "1" && amount1 >= 300 && amount1 <= 10000) {
                                let bal = parseInt(userdetails.balance);
                                const newbal = bal - amount1;
                                // 92 no of days 1.8 is percent 
                                let due = (parseFloat(SelectedInvestment.duration) * ((parseFloat(SelectedInvestment.profits) / 100) * amount1)) + amount1;
                                let myDate = new Date();
                                // multiply number of days with 24
                                const d2 = addHoursToDate(myDate, (parseFloat(SelectedInvestment.duration) * 24)).getTime()
                                var cap = new Date(d2);
                                console.log(cap.toString());
                                const subscription = {
                                    amount: amount1,
                                    currentPlan: SelectedInvestment.name,
                                    dueAmount: due,
                                    dueDate: d2,
                                    dateSubscribed: new Date().getTime(),
                                };
                                updateUserBalanceAndSub(newbal, subscription, SelectedInvestment.name, due);
                                Swal.fire(
                                    'Success!',
                                    'Plan Has Been Activated.',
                                    'success'
                                )
                                navigate("/dashboard")
                                // handleClick2("activation of BASIC was successful");
                                // }
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'You selected the wrong investment for selected amount',
                                    footer: '<a href="">Why do I have this issue?</a>'
                                })
                            }

                        } else {

                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'you dont have enough balance proceed to funding section to credit your account!',
                                footer: '<a href="">Why do I have this issue?</a>'
                            })
                            //   handleClick();
                            //show toast
                        }
                    }
                })


            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You Have Plan Running Currently!',
                })

            }
            //check this
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'fill in the requied fields!',
                footer: '<a href="">Why do I have this issue?</a>'
            })
            //show toast
        }
    };


    // Create Admin Investments   Create it as A FireBase Document
    // {userid,email,firebaseTime,transaction, details}


    const updateUserBalanceAndSub = (bal, subsription, plans, intrest) => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = db.collection("users").doc(userids);
        const increment = firebase.firestore.FieldValue.increment(intrest);
        washingtonRef
            .update({
                balance: bal,
                currentMining: subsription,
                totalearnings: increment,
                Investments: firebase.firestore.FieldValue.arrayUnion({
                    date: Date.now(),
                    plan: plans,
                    amount: amount,
                }),
            })
            .then(function () {
                console.log("Document successfully updated!");
                CreatUserObj(
                    {
                        date: Date.now(),
                        plan: plans,
                        amount: amount,
                    },
                    subsription

                )
            })
            .catch(function (error) {
                console.error("Error updating document: ", error);
            });
    };


    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const toggletrig = () => {
        const a = document.querySelector(".toggle-class")
        //const b = document.querySelector(".navbar")
        a.classList.toggle("active")


    }

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }



    return (
        <div>

            <div className="nk-app-root">
                <div className="nk-wrap ">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header-wrap">
                                <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                <div className="nk-header-menu" data-content="headerNav">
                                    <div className="nk-header-mobile">
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                    </div>
                                    <ul className="nk-menu nk-menu-main">
                                        <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/deposit" className="nk-menu-link"><span className="nk-menu-text">Deposit</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Deposit History</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Withdrawals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/mining" className="nk-menu-link"><span className="nk-menu-text">Mining plans</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Withdrawal history</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                    </ul>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav">
                                        <li className="dropdown notification-dropdown">
                                            <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right 2">
                                                <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                <div className="dropdown-body">
                                                    <div className="nk-notification">
                                                        <table className="table table-ulogs">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">Activity</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title">Details</span>
                                                                    </th>
                                                                </tr>
                                                            </thead><thead>
                                                                <tr>
                                                                    <td className="tb-col-os">Authentication</td>
                                                                    <td className="tb-col-ip">
                                                                        <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                    </td>
                                                                </tr><tr>
                                                                </tr></thead></table>
                                                    </div>
                                                    <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                </div>
                                            </div></li>
                                        <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                        <li className="dropdown user-dropdown order-sm-first">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                    <div className="user-info d-none d-xl-block">
                                                        <div className="user-status user-status-verified">verified</div>
                                                        <div className="user-name dropdown-indicator">Osita Stephen</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                    <div className="user-card">
                                                        <div className="user-avatar"><span>OS</span></div>
                                                        <div className="user-info"><span className="lead-text">Ositasteve</span><span className="sub-text">ositaositas@yahoo.com</span></div>
                                                        <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner user-account-info">
                                                    <h6 className="overline-title-alt">Account Balance</h6>
                                                    <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                    <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                </div>
                                                {/* <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                        <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                        <li><Link to="/"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                    </ul>
                                                </div> */}
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><Link to="/"> <em className="icon ni ni-signout" /><span>Sign out</span></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head text-center">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub"><span>Choose an Option</span></div>
                                            <div className="nk-block-head-content">
                                                <h2 className="nk-block-title fw-normal">Mining Plan</h2>
                                                <div className="nk-block-des">
                                                    <p>Choose your Mining plan to start earning.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <form className="plan-iv">
                                            {/* <div className="plan-iv-currency text-center">
                                                <ul className="nav nav-switch nav-tabs bg-white">
                                                    <li className="nav-item"><a href="#" className="nav-link" onClick={() => { setcoinToUse("Btc") }}>BTC</a></li>
                                                    <li className="nav-item"><a href="#" className="nav-link" onClick={() => { setcoinToUse("Usdt") }}>USDT Trc20</a></li>
                                                </ul>
                                            </div> */}
                                            <div className="plan-iv-list nk-slider nk-slider-s2">
                                                <ul className="plan-list slider-init" data-slick="{&quot;slidesToShow&quot;: 3, &quot;slidesToScroll&quot;: 1, &quot;infinite&quot;:false, &quot;responsive&quot;:[                {&quot;breakpoint&quot;: 992,&quot;settings&quot;:{&quot;slidesToShow&quot;: 2}},                  {&quot;breakpoint&quot;: 768,&quot;settings&quot;:{&quot;slidesToShow&quot;: 1}}             ]}">




                                                    <li className="plan-item" id="plan_1">
                                                        <input type="radio" id="plan-iv-1" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Amateur</h4>
                                                                    <p className="sub-text">Enjoy entry level of invest &amp; earn money.</p>
                                                                </div>

                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$200</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$5,000</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">20% Weekly Earnings</span></li>
                                                                        <li>
                                                                            <form >
                                                                                <h5 className="text-dark">Insert Amount to invest</h5>
                                                                                <input type="number" min={200} max={5000} onChange={(e) => { setamount(e.target.value) }} name="iamount" placeholder="$1000" className="form-control text-dark bg-light" /> <br />
                                                                                <input type="hidden" name="duration" defaultValue="5 Months" />
                                                                                <input type="hidden" name="id" defaultValue={5} />
                                                                                <input type="hidden" name="_token" defaultValue="zn8v4I2d4bZyK4wpUA2IxCApAntbf4ODlmrnGzIz" />

                                                                            </form>
                                                                        </li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">210%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_1')"><label htmlFor="plan-iv-1" className="plan-label"><span onClick={() => { planactivation("1") }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="plan-item" id="plan_6">
                                                        <input type="radio" id="plan-iv-6" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Master</h4>
                                                                    <p className="sub-text">Enjoy entry level of invest &amp; earn money.</p>
                                                                </div>

                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$5,000</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$25,000</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">25% Weekly Earnings</span></li>
                                                                        <li>
                                                                            <form >
                                                                                <h5 className="text-dark">Insert Amount to invest</h5>
                                                                                <input type="number" min={5000} max={25000} onChange={(e) => { setamount(e.target.value) }} name="iamount" placeholder="$1000" className="form-control text-dark bg-light" /> <br />
                                                                                <input type="hidden" name="duration" defaultValue="5 Months" />
                                                                                <input type="hidden" name="id" defaultValue={5} />
                                                                                <input type="hidden" name="_token" defaultValue="zn8v4I2d4bZyK4wpUA2IxCApAntbf4ODlmrnGzIz" />

                                                                            </form>
                                                                        </li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">210%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_6')"><label htmlFor="plan-iv-6" className="plan-label"><span onClick={() => { planactivation("2") }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="plan-item" id="plan_2">
                                                        <input type="radio" id="plan-iv-2" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Ultimate</h4>
                                                                    <p className="sub-text">Best plan for user to invest &amp; earn money.</p>
                                                                </div>

                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$25,000</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$100,000</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">30% Weekly Earnings</span></li>
                                                                        <li>
                                                                            <form >
                                                                                <h5 className="text-dark">Insert Amount to invest</h5>
                                                                                <input type="number" min={25000} max={10000} onChange={(e) => { setamount(e.target.value) }} name="iamount" placeholder="$1000" className="form-control text-dark bg-light" /> <br />
                                                                                <input type="hidden" name="duration" defaultValue="5 Months" />
                                                                                <input type="hidden" name="id" defaultValue={5} />
                                                                                <input type="hidden" name="_token" defaultValue="zn8v4I2d4bZyK4wpUA2IxCApAntbf4ODlmrnGzIz" />

                                                                            </form>
                                                                        </li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">540%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_2')"><label htmlFor="plan-iv-2" className="plan-label"><span onClick={() => { planactivation("3") }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="plan-item" id="plan_3">
                                                        <input type="radio" id="plan-iv-3" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Compound</h4>
                                                                    <p className="sub-text">Advance level of invest &amp; earn to quickly.</p>
                                                                </div>

                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$1,000</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$100,000</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">50% Weekly Earnings</span></li>
                                                                        <li>
                                                                            <form >
                                                                                <h5 className="text-dark">Insert Amount to invest</h5>
                                                                                <input type="number" min={1000} max={10000} onChange={(e) => { setamount(e.target.value) }} name="iamount" placeholder="$1000" className="form-control text-dark bg-light" /> <br />
                                                                                <input type="hidden" name="duration" defaultValue="5 Months" />
                                                                                <input type="hidden" name="id" defaultValue={5} />
                                                                                <input type="hidden" name="_token" defaultValue="zn8v4I2d4bZyK4wpUA2IxCApAntbf4ODlmrnGzIz" />

                                                                            </form>
                                                                        </li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">600%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_3')"><label htmlFor="plan-iv-3" className="plan-label"><span onClick={() => { planactivation("4") }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>





                                                </ul>
                                            </div>
                                            {/* <div className="plan-iv-actions text-center"><button className="btn btn-primary btn-lg" type="button" id="plan_button_selected" > <span onClick={proceedToInvestment}>Proceed to Invest</span> <em className="icon ni ni-arrow-right" /></button></div> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright"> © 2020  <a href="#">Crypto-mineclub Investment Management Company</a></div>
                                <div className="nk-footer-links">
                                    <ul className="nav nav-sm">
                                        {/* <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                           <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                           <li class="nav-item"><a class="nav-link" href="#">Help</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a></li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6>
                    <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-reset"><a href="#" className="reset-opt-setting">Reset Setting</a></div>
                </div>
            </div>
        </div >
    )
}

export default Mining